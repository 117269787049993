import React, { useEffect, useState, Fragment } from 'react';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import AppLoader from '../../../components/loaders/AppLoader';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET } from '../../../services/rest.service';
import DisputeModal from '../../../components/Modals/DisputeModal';
import BidList from './BidList';
import JobReviewForm from './JobReviewForm';
import {
  displayAmount,
  accessGeneralOrCargoType,
  toTitleCase,
  addQueryParamToUrl,
  getQueryParamValue,
} from '../../../helpers/utils';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import NotificationMenuItem from '../../../components/global/NotificationBadge';
import { NT_C_JOBS_UNDER_BIDDING, NT_C_JOBS_COMPLETED } from '../../../helpers/constants';
import { showSuspensionModal } from '../../../redux/user/UserActions';
import TruckDetailsModal from './TruckInformationModal';
import FeatureNotAvailable from '../../../components/Modals/FeatureNotAvailable';
import { readNotification } from '../../../redux/notification/NotificationActions';
// import ClientVerificationModal from '../../../components/Modals/ClientVerificationModal';
import DocumentsVerifyingMsg from '../../../components/Modals/DocumentsVerifyingMsg';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';
import JobReviewsListModal from './JobReviewsListModal';
import ClientRegSuccessModal from '../../../components/Modals/ClientRegSuccessModal';

const ClientJobs = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(getQueryParamValue(props.location, 'tab')?.toString() || '6');
  const [jobId, setJobId] = useState('');
  const [completedClearing, setCompletedClearing] = useState([]);
  const [completedForwarding, setCompletedForwarding] = useState([]);
  const serviceData = useSelector((state) => state.enumData);
  const [serviceType, setServiceType] = useState(
    getQueryParamValue(props.location, 'serviceType') || 'clearing',
  );
  const [jobList, setJobList] = useState([]);
  const [reviewAgentId, setReviewAgentId] = useState('');
  const [disputeModal, setDisputeModal] = useState(false);
  const [details, setDetails] = useState({});

  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const [verficationModal, setVerificationModal] = useState(false);
  const toggleVerificationModal = () => setVerificationModal((s) => !s);
  const [documentVerifyingModal, setDocumentVerifyingModal] = useState(false);
  const toggleDocumentVerifyingModal = () => setDocumentVerifyingModal((s) => !s);

  const resetTabStates = () => {
    setJobList([]);
    setSelectedPage(1);
    setTotalPages(0);
  };

  const handleSetServiceType = (serviceType) => {
    setServiceType(serviceType);
    setSelectedPage(1);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'serviceType', serviceType);
  };

  const toggleDisputeModal = (obj) => {
    setDetails(obj || null);
    setDisputeModal((s) => !s);
  };

  const switchTab = (data, notificationTypes) => {
    resetTabStates();
    setTab(data);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'tab', data);

    // mark the notifications of the tab as read
    if (notificationTypes) {
      dispatch(readNotification({ notificationTypes }));
    }

    // when switching to other tab reset to default service type
    if (data != 8) {
      setServiceType('clearing');
    }
  };

  const toggleJobId = (id) => {
    if (id !== jobId) {
      setJobId(id);
    } else {
      setJobId('');
    }
  };

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewListModal, setReviewListModal] = useState(false);
  const [reviewJob, setReviewJob] = useState(null);
  const toggleReviewModal = () => setReviewModal(!reviewModal);
  const [showFeatureNA, setShowFeatureNA] = useState(false);

  const handleJobReview = (job) => {
    setReviewJob(job);
    toggleReviewModal();
  };

  const toggleReviewListModal = (id) => {
    setReviewAgentId(id || '');
    setReviewListModal((s) => !s);
  };

  useEffect(() => {
    let pagination = {
      type: tab,
      selectedPage: selectedPage,
      serviceType: serviceType,
      itemsPerPage,
    };
    getJobList(pagination);
  }, [selectedPage, tab, serviceType, itemsPerPage]);

  // Show Job by Status
  const getJobList = (type) => {
    dispatch(showLoading());
    let url = `/jobs/getByStatus/${type.type}?page=${type.selectedPage}&per_page=${type.itemsPerPage}&sort_type=desc&sort_by=created_at`;
    GET(url)
      .then(({ data }) => {
        // console.log('data', data);
        if (type.type == 8) {
          const clearing = data.jobs.filter((obj) => obj.is_forwarding_only === false);
          setCompletedClearing(clearing);
          const forwarding = data.jobs.filter((obj) => obj.is_forwarding_only === true);
          setCompletedForwarding(forwarding);
          // console.log(clearing.length);
          // setTotalPages(clearing.length / 1);
          // console.log(type.serviceType);
          if (type.serviceType === 'clearing') {
            setTotalPages(data.clearing_count / type.itemsPerPage);
          } else {
            setTotalPages(data.forwarding_count / type.itemsPerPage);
          }
        } else {
          setTotalPages(data.total_count / type.itemsPerPage);
        }

        if (type.type === '5') {
          // for jobs whose bid is accepted and payment is not done
          // we need to display them at the top of the list
          const bidAcceptedJobs = [];
          const underBiddingJobs = [];
          data.jobs.forEach((j) => {
            if (j.clearing_status === '9' || j.forwarding_status === '9') {
              bidAcceptedJobs.push(j);
            } else {
              underBiddingJobs.push(j);
            }
          });

          setJobList([...bidAcceptedJobs, ...underBiddingJobs]);
        } else {
          setJobList(data.jobs);
        }

        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const refreshTabData = () => {
    let pagination = {
      type: tab,
      selectedPage: selectedPage,
      serviceType: serviceType,
      itemsPerPage,
    };
    getJobList(pagination);
  };

  const handlePostJob = () => {
    const userIdVerified = user?.identity_verified === 1;
    const userSuspended = user?.identity_verified === 3;

    // if (user?.account_no !== 'test') {
    //   setShowFeatureNA((s) => !s);
    // } else
    if (userSuspended) {
      dispatch(showSuspensionModal());
    } else if (!userIdVerified) {
      toggleVerificationModal();
    } else {
      props.history.push('/client/job/create-service-request');
    }
  };

  const [truckDetailsModal, setTruckDetailsModal] = useState(false);
  const [truckId, setTruckId] = useState('');

  const toggleTruckDetailsModal = (id) => {
    setTruckId(id || '');
    setTruckDetailsModal((s) => !s);
  };

  const handleProceedToPayment = (jobId, bidId) =>
    props.history.push(`/client/payments?job_id=${jobId}&bid_id=${bidId}`);

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="tabblock2" className="tabcontent">
        <div id="outcont">
          <section className="bodycont">
            <div className="">
              <div className="heading-with-btn mb-3">
                <div>
                  <h2>My Jobs</h2>
                </div>
                <div>
                  <button type="button" onClick={handlePostJob} className="simpleBtn">
                    Post a New Job
                  </button>
                </div>
              </div>
              <div className="servicetab">
                <ul className="nav nav-tabs responsive" id="nonResponsiveTabs1">
                  <li className={`test-class ${tab === '6' ? 'active' : ''}`}>
                    <a className="deco-none red-class" onClick={() => switchTab('6')}>
                      Jobs under Review
                    </a>
                  </li>
                  <li className={`test-class ${tab === '5' ? 'active' : ''}`}>
                    <a
                      className="deco-none red-class"
                      onClick={() => switchTab('5', NT_C_JOBS_UNDER_BIDDING)}
                    >
                      <NotificationMenuItem
                        title="Jobs under Bidding"
                        types={NT_C_JOBS_UNDER_BIDDING}
                      />
                    </a>
                  </li>
                  <li className={`test-class ${tab === '1' ? 'active' : ''}`}>
                    <a onClick={() => switchTab('1')}>Jobs in Progress</a>
                  </li>
                  <li className={`${tab === '8' ? 'active' : ''}`}>
                    <a className="deco-none" onClick={() => switchTab('8', NT_C_JOBS_COMPLETED)}>
                      <NotificationMenuItem title="Completed Jobs" types={NT_C_JOBS_COMPLETED} />
                    </a>
                  </li>
                </ul>

                <div className="tab-content responsive">
                  {tab === '6' && (
                    <div className={`tab-pane ${tab === '6' ? 'active' : ''}`} id="resp-tab6">
                      <div className="table-responsive clearingtable">
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                          <thead>
                            <tr>
                              <th>Job ID</th>
                              <th>Job Descriptions</th>
                              <th>Job Type</th>
                              <th>Created At</th>
                              <th>Operator Name</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jobList.length > 0 &&
                              jobList
                                .sort((a, b) => {
                                  if (a.job_id < b.job_id) return -1;
                                  if (a.job_id > b.job_id) return 1;
                                  return 0;
                                })
                                .map((x, index) => (
                                  <tr key={index}>
                                    <td>{x.job_id || 'N/A'}</td>
                                    <td className="description1">
                                      <h4>
                                        {capitalize(serviceData.job.category[x.category]) || 'N/A'}
                                      </h4>
                                      <p>
                                        {x.type && capitalize(serviceData.job.vehicle_type[x.type])}
                                      </p>
                                      <p>{accessGeneralOrCargoType(x, serviceData)}</p>
                                    </td>
                                    <td>
                                      {x.job_type_tags?.includes('forwarding') &&
                                      (x.forwarding_status === '3' ||
                                        x.clearing_status === '3' ||
                                        x.clearing_status === '12') ? (
                                        <p>--</p>
                                      ) : (
                                        x.job_type_tags.map((y, i) => (
                                          <div key={i}>
                                            <p
                                              className={`${
                                                y == 'clearing'
                                                  ? 'alert alert-info'
                                                  : 'alert alert-success'
                                              }`}
                                            >
                                              {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                            </p>
                                          </div>
                                        ))
                                      )}
                                    </td>
                                    <td>
                                      <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                                      <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                                    </td>
                                    <td>
                                      <p>
                                        {x.oprator?.first_name} {x.oprator?.last_name}
                                      </p>
                                    </td>
                                    <td>
                                      <div>
                                        {x.forwarding_status === '3' ||
                                        x.clearing_status === '3' ||
                                        x.clearing_status === '12' ? (
                                          <p className="alert alert-danger">Returned</p>
                                        ) : (
                                          <p className="alert  alert-info">Being Reviewed</p>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        onClick={() => {
                                          props.history.push(
                                            `/client/job/service/details?id=${x.uuid}`,
                                          );
                                        }}
                                        className="review color-secondary"
                                      >
                                        View Details
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                        {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                      </div>
                    </div>
                  )}

                  {tab === '5' && (
                    <div className={`tab-pane ${tab === '5' ? 'active' : ''}`} id="resp-tab4">
                      <div className="table-responsive clearingtable">
                        <table
                          className="table table-hover"
                          width="100%"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th>Job ID</th>
                              <th>Job Category</th>
                              <th>Job Type</th>
                              <th>Bid Closure</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {jobList.length > 0 &&
                              jobList
                                .sort((a, b) => {
                                  if (a.job_id < b.job_id) return -1;
                                  if (a.job_id > b.job_id) return 1;
                                  return 0;
                                })
                                .map((x, index) => (
                                  <Fragment key={`job-${x.uuid}`}>
                                    <tr>
                                      <td>
                                        {x.job_id}-{x.is_forwarding_only ? 'T' : 'C'}
                                      </td>
                                      <td>
                                        {toTitleCase(serviceData.job.category[x.category]) || 'N/A'}
                                      </td>
                                      <td>
                                        {x.job_type_tags.map((y, i) => (
                                          <div key={i}>
                                            <p
                                              className={`${
                                                y == 'clearing'
                                                  ? 'alert  alert-info'
                                                  : 'alert  alert-success'
                                              }`}
                                            >
                                              {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                            </p>
                                          </div>
                                        ))}
                                      </td>
                                      <td>
                                        <div>
                                          {x.bid_expiration_days ? (
                                            <p className="alert  alert-success">
                                              {toTitleCase(x.bid_expiration_days)} Left
                                            </p>
                                          ) : (
                                            <p className="alert  alert-danger">Job Expired</p>
                                          )}
                                        </div>
                                      </td>
                                      <td className="vertical-align-middle">
                                        <div className="wrap-div">
                                          <a
                                            onClick={() => {
                                              props.history.push(
                                                `/client/job/service/details?id=${x.uuid}`,
                                              );
                                            }}
                                            className="review mx-1 color-secondary"
                                          >
                                            View Details
                                          </a>
                                          {x.clearing_status === '9' ||
                                          x.forwarding_status === '9' ? (
                                            <a
                                              onClick={() =>
                                                handleProceedToPayment(
                                                  x.uuid,
                                                  x.clearing_accepted_bid ||
                                                    x.forwarding_accepted_bid,
                                                )
                                              }
                                              className="review mx-1"
                                            >
                                              Proceed to Payment
                                            </a>
                                          ) : (
                                            <a
                                              onClick={() => toggleJobId(x.uuid)}
                                              className="review mx-1"
                                            >
                                              View Bids
                                            </a>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="nopadding bid-list">
                                      <td colSpan="5">
                                        <BidList
                                          isOpen={jobId === x.uuid}
                                          toggleReviewListModal={toggleReviewListModal}
                                          job={x}
                                          toggleTruckDetailsModal={toggleTruckDetailsModal}
                                        />
                                      </td>
                                    </tr>
                                  </Fragment>
                                ))}
                          </tbody>
                        </table>
                        {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                      </div>
                    </div>
                  )}

                  {tab === '1' && (
                    <div className={`tab-pane ${tab === '1' ? 'active' : ''}`} id="resp-tab5">
                      <div className="table-responsive clearingtable">
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                          <thead>
                            <tr>
                              <th>Job ID</th>
                              <th>Job Desciption</th>
                              <th>Job Type</th>
                              <th>Created At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jobList.length > 0 &&
                              jobList
                                .sort((a, b) => {
                                  if (a.job_id < b.job_id) return -1;
                                  if (a.job_id > b.job_id) return 1;
                                  return 0;
                                })
                                .map((x, index) => (
                                  <tr key={index}>
                                    <td>
                                      {x.job_id}-{x.is_forwarding_only ? 'T' : 'C'}
                                    </td>
                                    <td className="description1">
                                      <h4>
                                        {capitalize(serviceData.job.category[x.category]) || 'N/A'}
                                      </h4>
                                      <p>
                                        {x.type && capitalize(serviceData.job.vehicle_type[x.type])}
                                      </p>
                                      <p>{accessGeneralOrCargoType(x, serviceData)}</p>
                                    </td>
                                    <td>
                                      {x.job_type_tags.map((y, i) => (
                                        <div key={i}>
                                          <p
                                            className={`${
                                              y == 'clearing'
                                                ? 'alert  alert-info'
                                                : 'alert  alert-success'
                                            }`}
                                          >
                                            {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                          </p>
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                                      <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                                    </td>
                                    <td>
                                      <div className="wrap-div">
                                        <a
                                          onClick={() => {
                                            props.history.push(
                                              `/client/job/service/details?id=${x.uuid}`,
                                            );
                                          }}
                                          className="review mx-1 color-secondary"
                                        >
                                          View Details
                                        </a>
                                        <a
                                          onClick={() => {
                                            props.history.push(`/client/job/details?id=${x.uuid}`);
                                          }}
                                          className="review mx-1"
                                        >
                                          View Progress
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                        {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                      </div>
                    </div>
                  )}
                  {tab === '8' && (
                    <div className={`tab-pane ${tab === '8' ? 'active' : ''}`} id="resp-tab6">
                      <div className="servicetabout">
                        <div className="resistertab">
                          <ul className="nav nav-tabs responsive" id="nonResponsiveTabs">
                            <li
                              className={`test-class ${serviceType === 'clearing' ? 'active' : ''}`}
                            >
                              <a
                                className="deco-none red-class"
                                onClick={() => handleSetServiceType('clearing')}
                              >
                                Clearing Service
                              </a>
                            </li>
                            <li
                              className={`test-class ${
                                serviceType === 'transport' ? 'active' : ''
                              }`}
                            >
                              <a onClick={() => handleSetServiceType('transport')}>
                                Transport Service
                              </a>
                            </li>
                          </ul>

                          <div className="tab-content responsive">
                            {serviceType === 'clearing' && (
                              <div className="tab-pane active" id="resp-tab7">
                                <div className="table-responsive clearingtable">
                                  <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <thead>
                                      <tr>
                                        <th>Job ID</th>
                                        <th>Job Type</th>
                                        <th>Price</th>
                                        <th>Date Started</th>
                                        <th>Date Completed</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {completedClearing.length !== 0 ? (
                                        completedClearing
                                          .sort((a, b) => {
                                            if (a.job_id < b.job_id) return -1;
                                            if (a.job_id > b.job_id) return 1;
                                            return 0;
                                          })
                                          .map((obj, i) => {
                                            const showOpenDispute =
                                              obj?.disputes?.length === 0 &&
                                              obj?.can_clearing_dispute;

                                            return (
                                              <tr key={obj.uuid}>
                                                <td>
                                                  {obj.job_id}-{obj.is_forwarding_only ? 'T' : 'C'}
                                                </td>
                                                <td>
                                                  {toTitleCase(
                                                    serviceData.job.category[obj.category],
                                                  ) || 'N/A'}
                                                </td>
                                                <td>{displayAmount(obj.clearing_bid)}</td>
                                                <td className="pt-1">
                                                  {obj.assigned_at ? (
                                                    <>
                                                      <p>
                                                        {moment(obj.assigned_at).format(
                                                          DATE_FORMAT,
                                                        )}
                                                      </p>
                                                      <p>
                                                        {moment(obj.assigned_at).format(
                                                          TIME_FORMAT,
                                                        )}
                                                      </p>
                                                    </>
                                                  ) : (
                                                    <p>N/A</p>
                                                  )}
                                                </td>
                                                <td className="pt-1">
                                                  <p>
                                                    {moment(
                                                      obj.clearing_completed_at ||
                                                        obj.forwarding_completed_at,
                                                    ).format(DATE_FORMAT)}
                                                  </p>
                                                  <p>
                                                    {moment(
                                                      obj.clearing_completed_at ||
                                                        obj.forwarding_completed_at,
                                                    ).format(TIME_FORMAT)}
                                                  </p>
                                                </td>
                                                <td>
                                                  <div className="starsec1 wrap-div">
                                                    <a
                                                      onClick={() => {
                                                        props.history.push(
                                                          `/client/job/service/details?id=${obj.uuid}`,
                                                        );
                                                      }}
                                                      className="review mx-1 color-secondary"
                                                    >
                                                      View Details
                                                    </a>
                                                    <a
                                                      onClick={() => {
                                                        props.history.push(
                                                          `/client/job/details?id=${obj.uuid}`,
                                                        );
                                                      }}
                                                      className="review mx-1"
                                                    >
                                                      View History
                                                    </a>
                                                    {showOpenDispute && (
                                                      <a
                                                        onClick={() => toggleDisputeModal(obj)}
                                                        className="review mx-1"
                                                      >
                                                        Open a Dispute
                                                      </a>
                                                    )}
                                                    {obj.job_ratings?.length === 0 && (
                                                      <a
                                                        onClick={() => handleJobReview(obj)}
                                                        className="review mx-1"
                                                      >
                                                        Post a Review
                                                      </a>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                      ) : (
                                        <tr>
                                          <td colSpan="6">
                                            <h3>No Data Found!</h3>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                            {serviceType === 'transport' && (
                              <div className="tab-pane active" id="resp-tab8">
                                <div className="table-responsive clearingtable">
                                  <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <thead>
                                      <tr>
                                        <th>Job ID</th>
                                        <th>Job Type</th>
                                        <th>Price</th>
                                        <th>Date Started</th>
                                        <th>Date Completed</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {completedForwarding.length !== 0 ? (
                                        completedForwarding
                                          .sort((a, b) => {
                                            if (a.job_id < b.job_id) return -1;
                                            if (a.job_id > b.job_id) return 1;
                                            return 0;
                                          })
                                          .map((obj, i) => {
                                            const showOpenDispute =
                                              obj?.disputes?.length === 0 &&
                                              obj?.can_forwarding_dispute;
                                            const insurancePurchased = obj.insurance_purchashed_on;

                                            return (
                                              <tr key={obj.uuid}>
                                                <td>
                                                  {obj.job_id}-{obj.is_forwarding_only ? 'T' : 'C'}
                                                </td>
                                                <td>
                                                  {toTitleCase(
                                                    serviceData.job.category[obj.category],
                                                  ) || 'N/A'}
                                                </td>
                                                <td>{displayAmount(obj.forwarding_bid)}</td>
                                                <td className="pt-1">
                                                  {obj.assigned_at ? (
                                                    <>
                                                      <p>
                                                        {moment(obj.assigned_at).format(
                                                          DATE_FORMAT,
                                                        )}
                                                      </p>
                                                      <p>
                                                        {moment(obj.assigned_at).format(
                                                          TIME_FORMAT,
                                                        )}
                                                      </p>
                                                    </>
                                                  ) : (
                                                    <p>N/A</p>
                                                  )}
                                                </td>
                                                <td className="pt-1">
                                                  <p>
                                                    {moment(
                                                      obj.clearing_completed_at ||
                                                        obj.forwarding_completed_at,
                                                    ).format(DATE_FORMAT)}
                                                  </p>
                                                  <p>
                                                    {moment(
                                                      obj.clearing_completed_at ||
                                                        obj.forwarding_completed_at,
                                                    ).format(TIME_FORMAT)}
                                                  </p>
                                                </td>
                                                <td>
                                                  <div className="starsec1 wrap-div">
                                                    <a
                                                      onClick={() => {
                                                        props.history.push(
                                                          `/client/job/service/details?id=${obj.uuid}`,
                                                        );
                                                      }}
                                                      className="review mx-1 color-secondary"
                                                    >
                                                      View Details
                                                    </a>
                                                    <a
                                                      onClick={() => {
                                                        props.history.push(
                                                          `/client/job/details?id=${obj.uuid}`,
                                                        );
                                                      }}
                                                      className="review mx-1"
                                                    >
                                                      View History
                                                    </a>
                                                    {showOpenDispute && (
                                                      <a
                                                        onClick={() => toggleDisputeModal(obj)}
                                                        className="review mx-1"
                                                      >
                                                        {insurancePurchased
                                                          ? 'Request Insurance Indemnity'
                                                          : 'Open a Dispute'}
                                                      </a>
                                                    )}
                                                    {obj.job_ratings?.length === 0 && (
                                                      <a
                                                        onClick={() => handleJobReview(obj)}
                                                        className="review mx-1"
                                                      >
                                                        Post a Review
                                                      </a>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                      ) : (
                                        <tr>
                                          <td colSpan="6">
                                            <h3>No Data Found!</h3>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {tab === '8' ? (
                  <>
                    {totalPages > 0 && (
                      <Pagination
                        pageCount={totalPages}
                        onPageChange={handleSelected}
                        selectedPage={selectedPage}
                        setItemsPerPage={handlePageCountChange}
                        itemsPerPage={itemsPerPage}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {jobList.length > 0 && (
                      <Pagination
                        pageCount={totalPages}
                        onPageChange={handleSelected}
                        selectedPage={selectedPage}
                        setItemsPerPage={handlePageCountChange}
                        itemsPerPage={itemsPerPage}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>

      <JobReviewForm
        isOpen={reviewModal}
        toggle={toggleReviewModal}
        job={reviewJob}
        refreshTabData={refreshTabData}
      />

      <JobReviewsListModal
        isOpen={reviewListModal}
        toggle={() => toggleReviewListModal()}
        reviewAgentId={reviewAgentId}
      />

      <TruckDetailsModal
        isOpen={truckDetailsModal}
        toggle={() => toggleTruckDetailsModal()}
        truckId={truckId}
        serviceData={serviceData}
      />
      {disputeModal && (
        <DisputeModal
          disputeModal={disputeModal}
          toggleDisputeModal={() => toggleDisputeModal(null)}
          jobDetails={details}
          disputeDetails={details?.disputes?.length > 0 ? details.disputes[0] : null}
          container="client-page"
          refreshData={refreshTabData}
        />
      )}
      <FeatureNotAvailable
        isOpen={showFeatureNA}
        toggle={() => setShowFeatureNA((s) => !s)}
        container="client-page"
      />
      {/* <ClientVerificationModal
        verficationModal={verficationModal}
        toggleVerificationModal={toggleVerificationModal}
        container="client-page"
      /> */}
      <ClientRegSuccessModal
        isOpen={verficationModal}
        toggle={toggleVerificationModal}
        container="client-page"
      />
      {documentVerifyingModal && (
        <DocumentsVerifyingMsg
          isOpen={documentVerifyingModal}
          toggle={toggleDocumentVerifyingModal}
          container="client-page"
        />
      )}
    </>
  );
};

export default ClientJobs;
