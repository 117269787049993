import Navixy from 'navixy-js-sdk';
import config from '../../config';

const API = new Navixy.Api({
  user: {
    hash: config.NAVIXY_API_KEY,
  },
});

export const getTrackersList = () => {
  return API.tracker.list();
};

export const getFuel = (trackerId) => {
  return API.request('tracker/get_fuel', { tracker_id: trackerId });
};

export const getDriverJournal = (trackerId, data) => {
  return API.request('driver/journal/proposal/list', { tracker_id: trackerId, ...data });
};

export const getTelemetry = (trackerId) => {
  return API.request('tracker/get_diagnostics', { tracker_id: trackerId });
};

export const generateHarshDrivingReport = (data) => {
  const payload = {
    plugin: {
      harsh_driving_penalties: {
        harshAcceleration: 3,
        harshBraking: 3,
        harshTurn: 3,
        harshAccelerationNTurn: 3,
        harshBrakingNTurn: 3,
        harshQuickLaneChange: 3,
      },
      speeding_penalties: {
        '10': 2,
        '20': 10,
        '30': 25,
        '50': 75,
      },
      speed_limit: 100,
      idling_penalty: 3,
      min_idling_duration: 5,
      min_speeding_duration: 1,
      check_road_rules_violations: false,
      use_vehicle_speed_limit: true,
      plugin_id: 46,
      show_seconds: false,
    },
    time_filter: {
      from: '00:00',
      to: '23:59',
      weekdays: [1, 3, 4, 5, 6, 7, 2],
    },
    from: data.from,
    to: data.to,
    trackers: [data.trackerId],
    plugin_id: 46,
    type: 'service',
  };
  return API.request('report/tracker/generate', payload);
};

// this is to check if report is ready before fetching
export const getHarshDrivingReportStatus = (reportId) => {
  return API.request('report/tracker/status', { report_id: reportId });
};

export const getHarshDrivingReport = (reportId) => {
  return API.request('report/tracker/retrieve', { report_id: reportId });
};

export const getTrackingData = async (trackerId, data) => {
  return API.request('track/read', {
    tracker_id: trackerId,
    ...data,
  });
};
