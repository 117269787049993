import React, { Fragment, useState } from 'react';
import { FieldArray, Formik, getIn } from 'formik';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { object, string, number, array, boolean } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from 'react-google-autocomplete';
import TextField from '../../components/UI/TextField';
import { POST } from '../../services/rest.service';
import AppLoader from '../../components/loaders/AppLoader';
import convertJsonToFormData from '../../helpers/helper';
import config from '../../../config';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';
import { formatCreateUpdateJobInitVals } from '../../helpers/utils';
import { acceptedFileTypes } from '../../helpers/constants';
import DocumentModal from '../../components/Modals/DocumentModal';
import ErrorMessage from '../../components/global/ErrorMessage';

const CreateServices = (props) => {
  // console.log('>>> props.location.state >>> ', props.location.state);
  const serviceData = useSelector((state) => state.enumData);
  const dispatch = useDispatch();
  const [documentToShow, setDocumentToShow] = useState('');
  const showDoc = (docc) => setDocumentToShow(docc);

  const createBothServices = (values, jobId = null) => {
    const payload = {
      category: values.category,
      type: values.category === '1' ? values.vehical_type : '',

      no_of_containers:
        values.category === '1'
          ? values.roroNoOfcontainer
          : values.category === '2'
          ? values.generalNoOfContainer
          : '',

      type_of_cargo: values.category === '1' ? '' : values.generalTypeOfcargo,
      single_item_title: values.category === '1' ? '' : values.generalItemName,
      paar_link: values.category === '1' ? values.roroPAAR : values.generalPAAR || '',

      lading_link: values.category === '1' ? values.roroBillLading : values.generalBillLading || '',

      date_of_arrival: moment(values.dateOfArrival).format('YYYY-MM-DD'),
      containers: values.category === '1' ? values.containers : [],
      has_forwarding: values.forwardingJob ? '1' : '0',
      port_of_discharge: values.forwardingLocation || '',
      destination: values.forwardingJob ? values.forwardingDestination : '',
      destination_state: values.forwardingJob ? values.forwardingDestinationState : '',
      tonnage: values.category === '3' ? values.bulkTonnage : '',
      performa_invoice: values.performa_invoice,
      clearing_discharge_port: values.clearingDischarge,
    };

    // this status is required for forwarding only job
    if (!values.clearingJob && values.forwardingJob) {
      payload.clearing_status = 11;
    }

    let URL = '/jobs/create';

    if (jobId) {
      URL = '/jobs/update';
      payload.job_uuid = jobId;
      // remove the paar & bill landing if they are not changed during update
      if (
        typeof payload.paar_link === 'string' &&
        payload.paar_link.startsWith('storage/documents/')
      ) {
        delete payload.paar_link;
      }
      if (
        typeof payload.lading_link === 'string' &&
        payload.lading_link.startsWith('storage/documents/')
      ) {
        delete payload.lading_link;
      }
      if (
        typeof payload.performa_invoice === 'string' &&
        payload.performa_invoice.startsWith('storage/documents/')
      ) {
        delete payload.performa_invoice;
      }
    }

    const formData = convertJsonToFormData(payload);
    // console.log('>> payload',payload , formData);

    dispatch(showLoading());

    POST(URL, formData)
      .then(({ data }) => {
        dispatch(
          showModal(jobId ? 'Job Updated succssefully!' : 'Request created succssefully!', true),
        );
        dispatch(hideLoading());
        if (jobId) {
          props.history.push('/client/jobs');
        } else {
          props.history.goBack();
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }
  // Logic for Updating the Job
  const jobDetails = props.location.state;
  // console.log('<<<< jobDetails >>>>', jobDetails);
  const formInitValues = formatCreateUpdateJobInitVals(jobDetails);
  // console.log('<<<< formInitValues >>>>', formInitValues);

  return (
    <div id="tabblock2" className="tabcontent">
      <div id="outcont">
        <section className="container" style={{ minHeight: '66vh' }}>
          <section className="bodycont create-service-form">
            <Formik
              initialValues={formInitValues}
              onSubmit={(values) => {
                // console.log('>> onSubmit', values);
                const jobId = values.jobId;
                createBothServices(values, jobId);
              }}
              validationSchema={object().shape({
                // NOTE: commented this since we are supporting only forwarding job also
                // clearingJob: boolean().when('forwardingJob', {
                //   is: true,
                //   then: boolean().required('Please select Job Type, Clearing Job is Mandatory'),
                // }),

                // forwardingJob: boolean().when(['clearingJob', 'roroType'], {
                //   is: (cj, rrt) => {
                //     return cj === true && rrt === true;
                //   },
                //   then: boolean().required('Please select Job Type'),
                // }),

                jobType: boolean().equals([true], 'Atleast One Job Type is required!'),
                category: string().required('Please select category'),

                vehical_type: string().when('vehicleType', {
                  is: true,
                  then: string().required('Please select vechile type'),
                }),

                // containers: array().when('roroType', {
                //   is: true,
                //   then: array().of(
                //     object().shape({
                //       roroNoOfvechile: number()
                //         .required('Number of Vechile is required')
                //         .min(1, 'Number of Vechile must be atleast 1'),
                //       vehicles: array().when('roroNoOfvechile', {
                //         is: (val) => val > 0,
                //         then: array().of(
                //           object().shape({
                //             brand: string().required('Brand is required'),
                //             model: string().required('Model is required'),
                //             year: string().required('Year is required'),
                //             vehicle_state: string().required('Condition is required'),
                //             vreg_no: string().required('VREG Number is required'),
                //           }),
                //         ),
                //       }),
                //     }),
                //   ),
                // }),
                roroNoOfcontainer: string().when('roroType', {
                  is: true,
                  then: string().required('No of container is required'),
                }),
                dateOfArrival: string().required('Expected date of arrival is required'),
                // roroBillLading: string().when('roroType', {
                //   is: true,
                //   then: string().required('Please upload bill of lading document'),
                // }),

                // for roro only PAAR is required
                // and for generalContainerType there is another field generalPAAR so this is not required
                // roroPAAR: string().when(['category', 'vehical_type', 'generalContainerType'], {
                //   is: (cat, vt, gct) => (gct ? false : !(cat === '1' && vt === '1')),
                //   then: string().required('Please upload PAAR document'),
                // }),

                // forwardingLocation: string().when('forwardingJob', {
                //   is: true,
                //   then: string().required('Please select location'),
                // }),
                // forwardingDestination: string().when('forwardingJob', {
                //   is: true,
                //   then: string().required('Please select destination'),
                // }),

                generalNoOfContainer: number().when('ContainerType', {
                  is: true,
                  then: number().required('No of container is required'),
                }),
                generalTypeOfcargo: string().when('generalContainerType', {
                  is: true,
                  then: string().required('Please select type'),
                }),
                // generalItemName: string().when('generalTypeOfcargoSingle', {
                //   is: true,
                //   then: string().required('Item name is required'),
                // }),
                // generalBillLading: string().when('generalContainerType', {
                //   is: true,
                //   then: string().required('Please upload bill of lading document'),
                // }),

                // generalPAAR: string().when('generalContainerType', {
                //   is: true,
                //   then: string().required('Please upload PAAR'),
                // }),

                // bulkTonnage: string().when('generalBulkCargoType', {
                //   is: true,
                //   then: string().required('Tonnage is required'),
                // }),

                // performa_invoice: string().when('forwardingJob', {
                //   is: true,
                //   then: string().required('Please upload Proforma Invoice'),
                // }),

                clearingDischarge: string().when('clearingJob', {
                  is: true,
                  then: string().required('Please select Port of Discharge'),
                }),
              })}
            >
              {(renderProps) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setValues,
                } = renderProps;
                // console.log('FORM values >>>>>', { values, touched, errors });
                const hidePAAR = values.category === '1' && values.vehical_type === '1';
                // conditions for which there is no forwarding job
                let hasNoForwardingJob = false;
                if (values.category == 3 && values.generalTypeOfcargo === '1') {
                  hasNoForwardingJob = true;
                }
                if (values.vehical_type === '1' && values.category === '1') {
                  hasNoForwardingJob = true;
                }

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="container">
                      <h2>Service Request</h2>

                      <div className="payment-info">
                        <ul
                          className="nav nav-tabs responsive justify-content-center checkbox-cls"
                          id="nonResponsiveTabs"
                          style={{ marginTop: '20px', marginBottom: '20px' }}
                        >
                          <li className="checkbox-inner-cls">
                            <input
                              type="checkbox"
                              className="inner-box"
                              id="clearing"
                              // defaultChecked
                              onChange={() => {
                                const fields = {
                                  clearingJob: !values.clearingJob,
                                  jobType: !values.clearingJob || values.forwardingJob,
                                };
                                setValues({
                                  ...values,
                                  ...fields,
                                });
                              }}
                              checked={values.clearingJob ? true : false}
                            />
                            <label className="deco-none red-class" htmlFor="clearing">
                              {' '}
                              Clearing Job
                            </label>
                          </li>
                          <li className="">
                            <input
                              type="checkbox"
                              id="fowarding"
                              className="inner-box"
                              onChange={() => {
                                const fields = {
                                  forwardingJob: !values.forwardingJob,
                                  jobType: !values.forwardingJob || values.clearingJob,
                                };
                                setValues({
                                  ...values,
                                  ...fields,
                                });
                              }}
                              checked={values.forwardingJob ? true : false}
                            />
                            <label className="deco-none red-class" htmlFor="fowarding">
                              {' '}
                              Transport Job
                            </label>
                          </li>
                          {getIn(touched, 'jobType') && getIn(errors, 'jobType') && (
                            <div className="invalid-feedback mb-3 display-block">
                              {getIn(errors, 'jobType')}
                            </div>
                          )}
                        </ul>

                        <div className="row">
                          {/* Clearing Form */}
                          <h3 className="text-lg-start">Clearing Services</h3>

                          <div className="col-sm-12 text-left">
                            <label>Select Category</label>

                            <select
                              className="form-control"
                              value={values.category}
                              onChange={(e) => {
                                const value = e.target.value;
                                const fields = {
                                  category: value,
                                  generalTypeOfcargo: '',
                                };

                                if (value === '1') {
                                  fields.vehicleType = true;
                                  fields.roroType = true;
                                  fields.generalType = false;
                                  fields.vehicalContainerType = false;
                                  fields.generalContainerType = false;
                                  fields.ContainerType = false;
                                  fields.generalBulkCargoType = false;
                                } else if (value === '2') {
                                  fields.vehicleType = false;
                                  fields.roroType = false;
                                  fields.generalType = true;
                                  fields.vehicalContainerType = false;
                                  fields.generalContainerType = true;
                                  fields.ContainerType = true;
                                  fields.generalBulkCargoType = false;
                                } else if (value === '3') {
                                  fields.vehicleType = false;
                                  fields.roroType = false;
                                  fields.generalType = false;
                                  fields.vehicalContainerType = false;
                                  fields.generalContainerType = true;
                                  fields.ContainerType = false;
                                  fields.generalBulkCargoType = true;
                                }
                                setValues({
                                  ...values,
                                  ...fields,
                                });
                              }}
                              id="category"
                              onBlur={handleBlur}
                            >
                              {Object.keys(serviceData.job.category).map((key) => (
                                <option value={key} key={key}>
                                  {capitalize(serviceData.job.category[key])}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/* <!-- Vehicle --> */}
                          {values.vehicleType && (
                            <div className="col-sm-12 text-left">
                              <label>Select Type</label>

                              <select
                                className="form-control"
                                value={values.vehical_type}
                                onBlur={handleBlur}
                                id="vehical_type"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const fields = {};
                                  if (value == 1) {
                                    const containersreset = [
                                      {
                                        roroNoOfvechile: 0,
                                        vehicles: [
                                          {
                                            brand: '',
                                            model: '',
                                            year: '',
                                            id: uuidv4(),
                                            vehicle_state: '',
                                            vreg_no: '',
                                          },
                                        ],
                                        id: uuidv4(),
                                        load_description: '',
                                      },
                                    ];
                                    fields.containers = containersreset;
                                    // setting forwarding false because we don't support this type of job
                                    fields.forwardingJob = false;
                                    fields.jobType = false;
                                  }
                                  fields.vehical_type = value;
                                  fields.roroType = true;
                                  fields.vehicalContainerType = false;
                                  setValues({
                                    ...values,
                                    ...fields,
                                  });
                                }}
                              >
                                {Object.keys(serviceData.job.vehicle_type).map((key) => (
                                  <Fragment key={key}>
                                    {key !== '3' ? (
                                      <option value={key}>
                                        {capitalize(serviceData.job.vehicle_type[key])}
                                      </option>
                                    ) : (
                                      ''
                                    )}
                                  </Fragment>
                                ))}
                              </select>
                            </div>
                          )}
                          {/* <!-- RORO --> */}
                          {values.roroType && (
                            <Fragment>
                              {values.vehical_type == 2 && (
                                <div className="col-sm-6 text-left">
                                  <label>Number of containers</label>

                                  <TextField
                                    value={values.roroNoOfcontainer}
                                    id="roroNoOfcontainer"
                                    name="roroNoOfcontainer"
                                    onChange={(e) => {
                                      const value = e.target.value ? parseInt(e.target.value) : '';
                                      const fields = {
                                        roroNoOfcontainer: value,
                                      };

                                      if (value >= 1) {
                                        let tempContainer = [];
                                        for (let i = 0; i < value; i++) {
                                          tempContainer.push({
                                            roroNoOfvechile: 0,
                                            vehicles: [],
                                            id: uuidv4(),
                                          });
                                        }
                                        fields.containers = tempContainer;
                                      } else {
                                        fields.containers = [];
                                      }
                                      setValues({
                                        ...values,
                                        ...fields,
                                      });
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      getIn(touched, 'roroNoOfcontainer') &&
                                      getIn(errors, 'roroNoOfcontainer')
                                    }
                                    type="number"
                                    className="form-control"
                                    placeholder="Number of containers"
                                    min="1"
                                    onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
                                  />
                                </div>
                              )}

                              <div className="col-sm-6 text-left">
                                <label>Expected date of arrival</label>
                                <TextField
                                  type="date"
                                  className="form-control"
                                  placeholder="Expected date of arrival: "
                                  value={values.dateOfArrival}
                                  name="dateOfArrival"
                                  id="dateOfArrival"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    getIn(touched, 'dateOfArrival') &&
                                    getIn(errors, 'dateOfArrival')
                                  }
                                  min={moment().format('YYYY-MM-DD')}
                                />
                              </div>
                              <hr />
                              <FieldArray
                                name="containers"
                                render={(arrayHelpers) => (
                                  <>
                                    {values.containers.map((containerData, index) => (
                                      <Fragment key={index}>
                                        {values.vehical_type == 2 && (
                                          <label className="text-left container-label">{`Container No ${index +
                                            1}`}</label>
                                        )}

                                        {values.vehical_type === '1' &&
                                        values.category === '1' ? null : (
                                          <>
                                            <div className="col-sm-6 text-left">
                                              <label>No of Vehicles</label>

                                              <TextField
                                                type="number"
                                                className="form-control"
                                                placeholder="vechile"
                                                value={containerData.roroNoOfvechile}
                                                min="0"
                                                name={`containers.${index}.roroNoOfvechile`}
                                                onChange={(e) => {
                                                  const value = e.target.value
                                                    ? parseInt(e.target.value)
                                                    : '';
                                                  setFieldValue(
                                                    `containers.${index}.roroNoOfvechile`,
                                                    value,
                                                  );
                                                  if (value >= 1) {
                                                    let tempVechile = [];
                                                    for (let i = 0; i < value; i++) {
                                                      tempVechile.push({
                                                        brand: '',
                                                        model: '',
                                                        year: '',
                                                        id: uuidv4(),
                                                        vehicle_state: '',
                                                        vreg_no: '',
                                                      });
                                                    }
                                                    setFieldValue(
                                                      `containers.${index}.vehicles`,
                                                      tempVechile,
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      `containers.${index}.vehicles`,
                                                      [],
                                                    );
                                                  }
                                                }}
                                                // onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                  getIn(touched, 'vechile') &&
                                                  getIn(errors, 'vechile')
                                                }
                                                onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
                                              />
                                              <p
                                                style={{ fontSize: '.875em' }}
                                                className="text-danger"
                                              >
                                                <ErrorMessage
                                                  name={`containers.${index}.roroNoOfvechile`}
                                                />
                                              </p>
                                            </div>
                                            {!(
                                              values.vehical_type === '1' && values.category === '1'
                                            ) && (
                                              <div className="col-sm-6 text-left">
                                                <label>Description of Load</label>
                                                <TextField
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Description"
                                                  value={containerData.load_description}
                                                  name={`containers.${index}.load_description`}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  error={
                                                    getIn(touched, 'load_description') &&
                                                    getIn(errors, 'load_description')
                                                  }
                                                />
                                                <p
                                                  style={{ fontSize: '.875em' }}
                                                  className="text-danger"
                                                >
                                                  <ErrorMessage
                                                    name={`containers.${index}.load_description`}
                                                  />
                                                </p>
                                              </div>
                                            )}
                                          </>
                                        )}
                                        <FieldArray
                                          name={`containers.${index}.vechile`}
                                          render={(arrayHelpers) => (
                                            <>
                                              {values.containers[index].vehicles.map(
                                                (vechileData, index1) => (
                                                  <Fragment key={index1}>
                                                    {values.vehical_type == 2 && (
                                                      <label className="text-left vehicle-label">{`Vehicle No ${index1 +
                                                        1}`}</label>
                                                    )}
                                                    <div className="col-sm-4 text-left">
                                                      <label>Brand</label>

                                                      <TextField
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Brand"
                                                        value={vechileData.brand}
                                                        name={`containers.${index}.vehicles.${index1}.brand`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                          getIn(touched, 'brand') &&
                                                          getIn(errors, 'brand')
                                                        }
                                                      />
                                                      <p
                                                        style={{ fontSize: '.875em' }}
                                                        className="text-danger"
                                                      >
                                                        <ErrorMessage
                                                          name={`containers.${index}.vehicles.${index1}.brand`}
                                                        />
                                                      </p>
                                                    </div>

                                                    <div className="col-sm-4 text-left">
                                                      <label>Model</label>
                                                      <TextField
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Model"
                                                        value={vechileData.model}
                                                        name={`containers.${index}.vehicles.${index1}.model`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                          getIn(touched, 'model') &&
                                                          getIn(errors, 'model')
                                                        }
                                                      />
                                                      <p
                                                        style={{ fontSize: '.875em' }}
                                                        className="text-danger"
                                                      >
                                                        <ErrorMessage
                                                          name={`containers.${index}.vehicles.${index1}.model`}
                                                        />
                                                      </p>
                                                    </div>
                                                    <div className="col-sm-4 text-left">
                                                      <label>Year</label>

                                                      <select
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Year"
                                                        value={vechileData.year}
                                                        name={`containers.${index}.vehicles.${index1}.year`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                          getIn(touched, 'year') &&
                                                          getIn(errors, 'year')
                                                        }
                                                      >
                                                        <option>Select Year</option>
                                                        {serviceData.year_list &&
                                                          serviceData.year_list.length &&
                                                          serviceData.year_list.map((year) => (
                                                            <option value={year} key={year}>
                                                              {year}
                                                            </option>
                                                          ))}
                                                      </select>
                                                      <p
                                                        style={{ fontSize: '.875em' }}
                                                        className="text-danger"
                                                      >
                                                        <ErrorMessage
                                                          name={`containers.${index}.vehicles.${index1}.year`}
                                                        />
                                                      </p>
                                                    </div>
                                                    <div className="col-sm-4 text-left">
                                                      <label>Condition</label>

                                                      <select
                                                        className="form-control"
                                                        value={vechileData.vehicle_state}
                                                        name={`containers.${index}.vehicles.${index1}.vehicle_state`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                          getIn(touched, 'vehicle_state') &&
                                                          getIn(errors, 'vehicle_state')
                                                        }
                                                      >
                                                        <option value="">Select Condition</option>
                                                        {Object.keys(
                                                          serviceData.job.vehicle_state,
                                                        ).map((key) => (
                                                          <option value={key} key={key}>
                                                            {capitalize(
                                                              serviceData.job.vehicle_state[key],
                                                            )}
                                                          </option>
                                                        ))}
                                                      </select>
                                                      <p
                                                        style={{ fontSize: '.875em' }}
                                                        className="text-danger"
                                                      >
                                                        <ErrorMessage
                                                          name={`containers.${index}.vehicles.${index1}.vehicle_state`}
                                                        />
                                                      </p>
                                                    </div>
                                                    <div className="col-sm-4 text-left">
                                                      <label>VREG Number</label>
                                                      <TextField
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="VREG Number"
                                                        value={vechileData.vreg_no}
                                                        name={`containers.${index}.vehicles.${index1}.vreg_no`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                          getIn(touched, 'vreg_no') &&
                                                          getIn(errors, 'vreg_no')
                                                        }
                                                      />
                                                      <p
                                                        style={{ fontSize: '.875em' }}
                                                        className="text-danger"
                                                      >
                                                        <ErrorMessage
                                                          name={`containers.${index}.vehicles.${index1}.vreg_no`}
                                                        />
                                                      </p>
                                                    </div>
                                                    <hr />
                                                  </Fragment>
                                                ),
                                              )}
                                            </>
                                          )}
                                        />
                                      </Fragment>
                                    ))}
                                  </>
                                )}
                              />

                              <div className="col-lg-6 col-sm-12 text-left mb-4">
                                {values.roroBillLading && values.jobId && (
                                  <div className="text-left">
                                    <span
                                      style={{ color: '#337ab7', cursor: 'pointer' }}
                                      onClick={() => showDoc(values.roroBillLading)}
                                    >
                                      <i className="fa fa-download"></i>Bill of Lading
                                    </span>
                                  </div>
                                )}
                                <label style={{ marginBottom: '10px' }} htmlFor="roroBillLading">
                                  Upload Bill of Lading
                                </label>

                                <input
                                  type="file"
                                  accept={acceptedFileTypes}
                                  className={` ${
                                    getIn(touched, 'roroBillLading') &&
                                    getIn(errors, 'roroBillLading')
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  placeholder="Upload Bill of Lading"
                                  name="roroBillLading"
                                  id="roroBillLading"
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      setFieldValue('roroBillLading', file);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                                {getIn(touched, 'roroBillLading') &&
                                getIn(errors, 'roroBillLading') ? (
                                  <div className="invalid-feedback mb-3">
                                    {getIn(touched, 'roroBillLading') &&
                                      getIn(errors, 'roroBillLading')}
                                  </div>
                                ) : null}
                              </div>

                              {!hidePAAR && (
                                <div className="col-lg-6 col-sm-12 text-left mb-4">
                                  {values.roroPAAR && values.jobId && (
                                    <div className="text-left">
                                      <span
                                        style={{ color: '#337ab7', cursor: 'pointer' }}
                                        onClick={() => showDoc(values.roroPAAR)}
                                      >
                                        <i className="fa fa-download"></i>PAAR (pre-arrival
                                        assessment report)
                                      </span>
                                    </div>
                                  )}
                                  <label style={{ marginBottom: '10px' }}>
                                    Upload PAAR (pre-arrival assessment report)
                                  </label>

                                  <input
                                    type="file"
                                    accept={acceptedFileTypes}
                                    className={` ${
                                      getIn(touched, 'roroPAAR') && getIn(errors, 'roroPAAR')
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    placeholder="Upload pre-arrival assessment report (PAAR)"
                                    name="roroPAAR"
                                    id="roroPAAR"
                                    onChange={(e) => {
                                      const file = e.target.files[0];
                                      if (file) {
                                        setFieldValue('roroPAAR', file);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {getIn(touched, 'roroPAAR') && getIn(errors, 'roroPAAR') ? (
                                    <div className="invalid-feedback mb-3">
                                      {getIn(touched, 'roroPAAR') && getIn(errors, 'roroPAAR')}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Fragment>
                          )}

                          {/* <!-- End RORO --> */}

                          {/* <!-- General Cargo & Bulk Cargo --> */}
                          {/* <!-- Container for clearing --> */}
                          {values.generalContainerType && (
                            <Fragment>
                              <div className="col-sm-12 text-left">
                                <label>Select type of cargo</label>

                                <select
                                  className={`form-control ${
                                    getIn(touched, 'generalTypeOfcargo') &&
                                    getIn(errors, 'generalTypeOfcargo')
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  value={values.generalTypeOfcargo}
                                  onBlur={handleBlur}
                                  id="generalTypeOfcargo"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const fields = {
                                      generalTypeOfcargo: value,
                                    };

                                    // Bulk & wet - no forwarding (just like roro)
                                    if (values.category == '3' && value === '1') {
                                      // setting forwarding false because we don't support this type of job
                                      fields.forwardingJob = false;
                                      fields.jobType = false;
                                    }

                                    // to show item name field
                                    if (values.category == '2' && value === '1') {
                                      fields.generalTypeOfcargoSingle = true;
                                    } else if (values.category == '3') {
                                      fields.generalTypeOfcargoSingle = true;
                                    } else {
                                      fields.generalTypeOfcargoSingle = false;
                                    }
                                    setValues({
                                      ...values,
                                      ...fields,
                                    });
                                  }}
                                >
                                  <option value={''}>Select</option>
                                  {values.category == '2'
                                    ? Object.keys(serviceData.job.type_of_general).map((key) => (
                                        <option value={key} key={key}>
                                          {capitalize(serviceData.job.type_of_general[key])}
                                        </option>
                                      ))
                                    : Object.keys(serviceData.job.type_of_cargo).map((key) => (
                                        <option value={key} key={key}>
                                          {capitalize(serviceData.job.type_of_cargo[key])}
                                        </option>
                                      ))}
                                </select>
                                {getIn(touched, 'generalTypeOfcargo') &&
                                getIn(errors, 'generalTypeOfcargo') ? (
                                  <div className="invalid-feedback mb-3">
                                    {getIn(touched, 'generalTypeOfcargo') &&
                                      getIn(errors, 'generalTypeOfcargo')}
                                  </div>
                                ) : null}
                              </div>

                              <div className="col-sm-6 text-left">
                                <label>
                                  Item Name{' '}
                                  {!values.generalTypeOfcargoSingle && '(comma separated names)'}
                                </label>

                                <TextField
                                  value={values.generalItemName}
                                  id="generalItemName"
                                  name="generalItemName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    getIn(touched, 'generalItemName') &&
                                    getIn(errors, 'generalItemName')
                                  }
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    values.generalTypeOfcargoSingle ? 'Item Name' : 'Item 1, Item 2'
                                  }
                                />
                              </div>

                              <div className="col-sm-6 text-left">
                                <label>Expected date of arrival</label>
                                <TextField
                                  type="date"
                                  className="form-control"
                                  placeholder="Expected date of arrival: "
                                  value={values.dateOfArrival}
                                  name="dateOfArrival"
                                  id="dateOfArrival"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    getIn(touched, 'dateOfArrival') &&
                                    getIn(errors, 'dateOfArrival')
                                  }
                                  min={moment().format('YYYY-MM-DD')}
                                />
                              </div>

                              {values.generalType ? (
                                <div className="col-sm-6 text-left">
                                  <label>Number of containers</label>

                                  <TextField
                                    value={values.generalNoOfContainer}
                                    id="generalNoOfContainer"
                                    name="generalNoOfContainer"
                                    onChange={(e) =>
                                      setFieldValue(
                                        'generalNoOfContainer',
                                        parseInt(e.target.value),
                                      )
                                    }
                                    onBlur={handleBlur}
                                    error={
                                      getIn(touched, 'generalNoOfContainer') &&
                                      getIn(errors, 'generalNoOfContainer')
                                    }
                                    type="number"
                                    className="form-control"
                                    placeholder="Number of containers"
                                    min="1"
                                    onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
                                  />
                                </div>
                              ) : (
                                <div className="col-sm-6 text-left">
                                  <label>Tonnage</label>
                                  <TextField
                                    value={values.bulkTonnage}
                                    id="bulkTonnage"
                                    name="bulkTonnage"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      getIn(touched, 'bulkTonnage') && getIn(errors, 'bulkTonnage')
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="Tonnage"
                                  />
                                </div>
                              )}
                              <div className="col-lg-6 col-sm-12 text-left mb-4">
                                {values.generalPAAR && values.jobId && (
                                  <div className="text-left">
                                    <span
                                      style={{ color: '#337ab7', cursor: 'pointer' }}
                                      onClick={() => showDoc(values.generalPAAR)}
                                    >
                                      <i className="fa fa-download"></i>PAAR (pre-arrival assessment
                                      report)
                                    </span>
                                  </div>
                                )}
                                <label style={{ marginBottom: '10px' }}>
                                  Upload PAAR (pre-arrival assessment report)
                                </label>

                                <input
                                  type="file"
                                  accept={acceptedFileTypes}
                                  className={` ${
                                    getIn(touched, 'generalPAAR') && getIn(errors, 'generalPAAR')
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  placeholder="Upload Bill of Lading"
                                  name="generalPAAR"
                                  id="generalPAAR"
                                  onChange={(e) => {
                                    const file = e.target.files[0];

                                    if (file) {
                                      setFieldValue('generalPAAR', file);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                                {getIn(touched, 'generalPAAR') && getIn(errors, 'generalPAAR') ? (
                                  <div className="invalid-feedback mb-3">
                                    {getIn(touched, 'generalPAAR') && getIn(errors, 'generalPAAR')}
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="col-lg-6 col-sm-12 text-left mb-4"
                                style={{ marginBottom: '10px' }}
                              >
                                {values.generalBillLading && values.jobId && (
                                  <div className="text-left">
                                    <span
                                      style={{ color: '#337ab7', cursor: 'pointer' }}
                                      onClick={() => showDoc(values.generalBillLading)}
                                    >
                                      <i className="fa fa-download"></i>Bill of Lading
                                    </span>
                                  </div>
                                )}
                                <label style={{ marginBottom: '10px' }}>
                                  Upload Bill of Lading
                                </label>

                                <input
                                  type="file"
                                  accept={acceptedFileTypes}
                                  className={` ${
                                    getIn(touched, 'generalBillLading') &&
                                    getIn(errors, 'generalBillLading')
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  placeholder="Upload Bill of Lading"
                                  name="generalBillLading"
                                  id="generalBillLading"
                                  onChange={(e) => {
                                    const file = e.target.files[0];

                                    if (file) {
                                      setFieldValue('generalBillLading', file);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                                {getIn(touched, 'generalBillLading') &&
                                getIn(errors, 'generalBillLading') ? (
                                  <div className="invalid-feedback mb-3">
                                    {getIn(touched, 'generalBillLading') &&
                                      getIn(errors, 'generalBillLading')}
                                  </div>
                                ) : null}
                              </div>
                            </Fragment>
                          )}

                          {values.clearingJob ? (
                            <div className="col-lg-6 col-sm-6 text-left">
                              <label>Port of Discharge</label>
                              <select
                                className={`form-control ${
                                  getIn(touched, 'clearingDischarge') &&
                                  getIn(errors, 'clearingDischarge')
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={values.clearingDischarge}
                                name="clearingDischarge"
                                id="clearingDischarge"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="">Select Port</option>
                                {serviceData?.ports?.length > 0 &&
                                  serviceData?.ports.map((obj) => (
                                    <option value={obj.uuid} key={obj.uuid}>
                                      {capitalize(obj.port_of_operation)}
                                    </option>
                                  ))}
                              </select>
                              {getIn(touched, 'clearingDischarge') &&
                              getIn(errors, 'clearingDischarge') ? (
                                <div className="invalid-feedback mb-3">
                                  {getIn(errors, 'clearingDischarge')}
                                </div>
                              ) : null}
                            </div>
                          ) : null}

                          {/* <div className="col-lg-6 col-sm-6 text-left">
                            <label>Pick-up Location</label>
                            <select
                              className={`form-control ${
                                getIn(touched, 'forwardingLocation') &&
                                getIn(errors, 'forwardingLocation')
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value={values.forwardingLocation}
                              name="forwardingLocation"
                              id="forwardingLocation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Port</option>
                              {serviceData?.ports?.length > 0 &&
                                serviceData?.ports.map((obj) => (
                                  <option value={obj.uuid} key={obj.uuid}>
                                    {capitalize(obj.port_of_operation)}
                                  </option>
                                ))}
                            </select>
                            {getIn(touched, 'forwardingLocation') &&
                            getIn(errors, 'forwardingLocation') ? (
                              <div className="invalid-feedback mb-3">
                                {getIn(touched, 'forwardingLocation') &&
                                  getIn(errors, 'forwardingLocation')}
                              </div>
                            ) : null}
                          </div> */}
                          {/* Transport Job */}
                          {values.forwardingJob && (
                            <div style={{ marginTop: '20px' }}>
                              <h3 className="text-lg-start">Transport Services</h3>

                              {hasNoForwardingJob ? (
                                <p>
                                  Note: Sorry we don't provide transport services for this type of
                                  job!
                                </p>
                              ) : (
                                <Fragment>
                                  {/* <div className="col-lg-6 col-sm-6 text-left">
                                    <label>Port of discharge</label>
                                    <select
                                      className={`form-control ${
                                        getIn(touched, 'forwardingLocation') &&
                                        getIn(errors, 'forwardingLocation')
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      value={values.forwardingLocation}
                                      name="forwardingLocation"
                                      id="forwardingLocation"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="">Select Port</option>
                                      {serviceData?.ports?.length > 0 &&
                                        serviceData?.ports.map((obj) => (
                                          <option value={obj.uuid} key={obj.uuid}>
                                            {capitalize(obj.port_of_operation)}
                                          </option>
                                        ))}
                                    </select>
                                    {getIn(touched, 'forwardingLocation') &&
                                    getIn(errors, 'forwardingLocation') ? (
                                      <div className="invalid-feedback mb-3">
                                        {getIn(touched, 'forwardingLocation') &&
                                          getIn(errors, 'forwardingLocation')}
                                      </div>
                                    ) : null}
                                  </div> */}

                                  <div className="col-lg-6 col-sm-6 text-left">
                                    <label>Pick-up Location</label>
                                    <select
                                      className={`form-control ${
                                        getIn(touched, 'forwardingLocation') &&
                                        getIn(errors, 'forwardingLocation')
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      value={values.forwardingLocation}
                                      name="forwardingLocation"
                                      id="forwardingLocation"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="">Select Port</option>
                                      {serviceData?.ports?.length > 0 &&
                                        serviceData?.ports.map((obj) => (
                                          <option value={obj.uuid} key={obj.uuid}>
                                            {capitalize(obj.port_of_operation)}
                                          </option>
                                        ))}
                                    </select>
                                    {getIn(touched, 'forwardingLocation') &&
                                    getIn(errors, 'forwardingLocation') ? (
                                      <div className="invalid-feedback mb-3">
                                        {getIn(touched, 'forwardingLocation') &&
                                          getIn(errors, 'forwardingLocation')}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="col-lg-6 col-sm-6 text-left">
                                    <label>Destination</label>
                                    <Autocomplete
                                      placeholder="Full Address"
                                      value={values.forwardingDestination}
                                      options={{
                                        types: [],
                                        componentRestrictions: {
                                          country: 'ng',
                                        },
                                      }}
                                      onChange={(e) =>
                                        setFieldValue('forwardingDestination', e.target.value)
                                      }
                                      onBlur={handleBlur}
                                      apiKey={config.GOOGLE_API_KEY}
                                      onPlaceSelected={(place) => {
                                        setFieldValue(
                                          'forwardingDestination',
                                          place.formatted_address,
                                        );
                                      }}
                                      className={`form-control ${
                                        getIn(touched, 'forwardingDestination') &&
                                        getIn(errors, 'forwardingDestination')
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                    />{' '}
                                    {getIn(touched, 'forwardingDestination') &&
                                    getIn(errors, 'forwardingDestination') ? (
                                      <div className="invalid-feedback mb-3">
                                        {getIn(touched, 'forwardingDestination') &&
                                          getIn(errors, 'forwardingDestination')}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-lg-6 col-sm-6 text-left">
                                    <label>State</label>
                                    <select
                                      type="text"
                                      className="form-control"
                                      placeholder="Year"
                                      value={values.forwardingDestinationState}
                                      name="forwardingDestinationState"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={getIn(touched, 'year') && getIn(errors, 'year')}
                                    >
                                      <option>Select State</option>
                                      {serviceData.states?.length > 0 &&
                                        serviceData.states.map((state) => (
                                          <option value={state.uuid} key={state.uuid}>
                                            {state.state}
                                          </option>
                                        ))}
                                    </select>
                                    {getIn(touched, 'forwardingDestinationState') &&
                                    getIn(errors, 'forwardingDestinationState') ? (
                                      <div className="invalid-feedback mb-3">
                                        {getIn(touched, 'forwardingDestinationState') &&
                                          getIn(errors, 'forwardingDestinationState')}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-lg-6 col-sm-12 text-left mb-4">
                                    {values.performa_invoice && values.jobId && (
                                      <div className="text-left">
                                        <span
                                          style={{ color: '#337ab7', cursor: 'pointer' }}
                                          onClick={() => showDoc(values.performa_invoice)}
                                        >
                                          <i className="fa fa-download"></i>Proforma Invoice
                                        </span>
                                      </div>
                                    )}
                                    <label style={{ marginBottom: '10px' }}>
                                      Upload Proforma Invoice
                                    </label>

                                    <input
                                      type="file"
                                      accept={acceptedFileTypes}
                                      className={` ${
                                        getIn(touched, 'performa_invoice') &&
                                        getIn(errors, 'performa_invoice')
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      name="performa_invoice"
                                      id="performa_invoice"
                                      onChange={(e) => {
                                        const file = e.target.files[0];

                                        if (file) {
                                          setFieldValue('performa_invoice', file);
                                        }
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    {getIn(touched, 'performa_invoice') &&
                                    getIn(errors, 'performa_invoice') ? (
                                      <div className="invalid-feedback mb-3">
                                        {getIn(touched, 'performa_invoice') &&
                                          getIn(errors, 'performa_invoice')}
                                      </div>
                                    ) : null}
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          )}

                          <div className="col-sm-12">
                            <div className="buttonsec">
                              <button
                                type="button"
                                className="submitbut btn-light"
                                onClick={() => {
                                  props.history.goBack();
                                }}
                              >
                                Back
                              </button>
                              <button
                                type="submit"
                                className="submitbut"
                                disabled={values.forwardingJob && hasNoForwardingJob}
                              >
                                {values.jobId ? 'Update Job' : 'Submit'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </section>
        </section>
      </div>
      {/* Modal to Display Document */}
      {documentToShow && (
        <DocumentModal
          isOpen={documentToShow}
          toggle={() => setDocumentToShow('')}
          documentToShow={documentToShow}
          container="client-page"
        />
      )}
    </div>
  );
};

export default CreateServices;
