import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Polyline, Marker, useJsApiLoader } from '@react-google-maps/api';
import configObject from '../../../config';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const center = {
  lat: 6.4432183,
  lng: 3.3702666,
  // lat: -3.745,
  // lng: -38.523,
};

// const path = [
//   { lat: 37.772, lng: -122.214 },
//   { lat: 21.291, lng: -157.821 },
//   { lat: -18.142, lng: 178.431 },
//   { lat: -27.467, lng: 152.027 },
// ];

function MapContainer({ placeCenter, coordinates }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: configObject.GOOGLE_MAPS_API_KEY,
  });
  const [center, setCenter] = useState({
    lat: 6.4432183,
    lng: 3.3702666,
  });

  const [map, setMap] = useState(null);

  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  // console.log(coordinates);

  // useEffect(() => {
  //   if (placeCenter) {
  //     setCenter(placeCenter);
  //   }
  // }, [placeCenter]);
  // console.log(coordinates);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={coordinates?.[0]} />
      <Marker position={coordinates?.at(-1)} />
      <Polyline path={coordinates} options={options} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MapContainer);
