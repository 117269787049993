const dev = {
  api: {
    // URL: 'http://127.0.0.1:8000/api/v1',
    URL: 'https://api.goagent.com.ng/api/v1',
    BASE_URL: 'https://api.goagent.com.ng',
  },
  CLIENT_ID: 'ARLnJ0E-0hG7lhvE720nJEW8czGIRTEWy8lolOiDcFy2cAxY1WGo1C9nJHauC8mYpnTqTWAmH3JBvJaL',
  CLIENT_SECRET: '',
  PAYSTACK_KEY: 'pk_live_940593fd447b45dfc792d302a5251ec657c96922',
  FLUTTERWAVE_KEY: 'FLWPUBK-e70f9d1eb1731f331448a480a8d31973-X',
  VERIFYME_API_URL: 'https://vapi.verifyme.ng/v1/verifications/identities',
  VERIFYME_KEY:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEwODk1NywiZW52IjoibGl2ZSIsImlhdCI6MTYzNTkzNDMxMn0.fCG3YsD1YVl30znEB_0oYICSNGhqDXfR0lVqA6bxqEQ',
  NAVIXY_API_URL: 'https://api.eu.navixy.com/v2',
  NAVIXY_WEBSOCKER_URL: 'wss://api.navixy.com/v2',
  NAVIXY_API_KEY: 'f3c75e52b6d04a600a7a71a1b786ff62',
  GOOGLE_API_KEY: 'AIzaSyA3Yt-yFcjQnpVCoA3E_jRN7-U3w54VoUU',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBB9mR7mz8BShYOlPIuFrSf-FHbxFLRpRk',
  NAVIXY_WEBLOCATOR_KEY: '7b584a6e2b6fddefd8f3512495361d38',
  NAVIXY_WEBLOCATOR_URL: '//23422.navixy.com/pro/applications/locator',
  REMITA_PUBLIC_KEY:
    'QzAwMDA0MDU1NDR8ODQzOTA2NzY4OHwxZTE4MTg2M2ViNWY3ZTA0MzNkZWM0MDViNmUzOTY5ZDYwMjM3ZGI1MzM2MWY3OTE4NjI3YWZlYjgyY2QzMjNiNmQwZGFjY2UxZDBiYjA1OWIzNjU0YzI4ZGE3NzMyNDI4OTk0NDY0ZGRhNzUzNzU5OWFiMjg1Zjg5NzRlNTIwNQ==',
  REMITA_GEN_RRR_URL:
    'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api',
  REMITA_MERCHANT_ID: '8439067688',
  REMITA_API_KEY: '5HCCY3MQ',
  REMITA_PENALTY_SERVICETYPE_ID: '9055697131',
  REMITA_FORWARDING_SERVICETYPE_ID: '9055616392',
  REMITA_CLEARING_SERVICETYPE_ID: '9055599678',
  REMITA_SUBSCRIPTION_SERVICETYPE_ID: '9055457831',
  GOOGLE_ANALYTICS: 'G-4PBDHS1XR0',
  TOKEN_EXPIRY_TIME: 5, // in minutes
  APP_INACTIVITY_TIME: 10, // in minutes
};

const stage = {
  api: {
    URL: 'https://api.goagent.com.ng/api/v1',
    BASE_URL: 'https://api.goagent.com.ng',
  },
  CLIENT_ID: 'ARLnJ0E-0hG7lhvE720nJEW8czGIRTEWy8lolOiDcFy2cAxY1WGo1C9nJHauC8mYpnTqTWAmH3JBvJaL',
  CLIENT_SECRET: '',
  PAYSTACK_KEY: 'pk_live_940593fd447b45dfc792d302a5251ec657c96922',
  FLUTTERWAVE_KEY: 'FLWPUBK-e70f9d1eb1731f331448a480a8d31973-X',
  VERIFYME_API_URL: 'https://vapi.verifyme.ng/v1/verifications/identities',
  VERIFYME_KEY:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEwODk1NywiZW52IjoibGl2ZSIsImlhdCI6MTYzNTkzNDMxMn0.fCG3YsD1YVl30znEB_0oYICSNGhqDXfR0lVqA6bxqEQ',
  NAVIXY_API_URL: 'https://api.eu.navixy.com/v2',
  NAVIXY_WEBSOCKER_URL: 'wss://api.navixy.com/v2',
  NAVIXY_API_KEY: 'f3c75e52b6d04a600a7a71a1b786ff62',
  GOOGLE_API_KEY: 'AIzaSyA3Yt-yFcjQnpVCoA3E_jRN7-U3w54VoUU',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBB9mR7mz8BShYOlPIuFrSf-FHbxFLRpRk',
  NAVIXY_WEBLOCATOR_KEY: '7b584a6e2b6fddefd8f3512495361d38',
  NAVIXY_WEBLOCATOR_URL: '//23422.navixy.com/pro/applications/locator',
  REMITA_PUBLIC_KEY:
    'QzAwMDA0MDU1NDR8ODQzOTA2NzY4OHwxZTE4MTg2M2ViNWY3ZTA0MzNkZWM0MDViNmUzOTY5ZDYwMjM3ZGI1MzM2MWY3OTE4NjI3YWZlYjgyY2QzMjNiNmQwZGFjY2UxZDBiYjA1OWIzNjU0YzI4ZGE3NzMyNDI4OTk0NDY0ZGRhNzUzNzU5OWFiMjg1Zjg5NzRlNTIwNQ==',
  REMITA_GEN_RRR_URL:
    'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api',
  REMITA_MERCHANT_ID: '8439067688',
  REMITA_API_KEY: '5HCCY3MQ',
  REMITA_PENALTY_SERVICETYPE_ID: '9055697131',
  REMITA_FORWARDING_SERVICETYPE_ID: '9055616392',
  REMITA_CLEARING_SERVICETYPE_ID: '9055599678',
  REMITA_SUBSCRIPTION_SERVICETYPE_ID: '9055457831',
  GOOGLE_ANALYTICS: 'G-4PBDHS1XR0',
  TOKEN_EXPIRY_TIME: 5, // in minutes
  APP_INACTIVITY_TIME: 10, // in minutes
};

const prod = {
  api: {
    URL: 'https://api.goagent.com.ng/api/v1',
    BASE_URL: 'https://api.goagent.com.ng',
  },
  CLIENT_ID: 'ARLnJ0E-0hG7lhvE720nJEW8czGIRTEWy8lolOiDcFy2cAxY1WGo1C9nJHauC8mYpnTqTWAmH3JBvJaL',
  CLIENT_SECRET: '',
  PAYSTACK_KEY: 'pk_live_940593fd447b45dfc792d302a5251ec657c96922',
  FLUTTERWAVE_KEY: 'FLWPUBK-e70f9d1eb1731f331448a480a8d31973-X',
  VERIFYME_API_URL: 'https://vapi.verifyme.ng/v1/verifications/identities',
  VERIFYME_KEY:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEwODk1NywiZW52IjoibGl2ZSIsImlhdCI6MTYzNTkzNDMxMn0.fCG3YsD1YVl30znEB_0oYICSNGhqDXfR0lVqA6bxqEQ',
  NAVIXY_API_URL: 'https://api.eu.navixy.com/v2',
  NAVIXY_WEBSOCKER_URL: 'wss://api.navixy.com/v2',
  NAVIXY_API_KEY: 'f3c75e52b6d04a600a7a71a1b786ff62',
  GOOGLE_API_KEY: 'AIzaSyA3Yt-yFcjQnpVCoA3E_jRN7-U3w54VoUU',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBB9mR7mz8BShYOlPIuFrSf-FHbxFLRpRk',
  NAVIXY_WEBLOCATOR_KEY: '7b584a6e2b6fddefd8f3512495361d38',
  NAVIXY_WEBLOCATOR_URL: '//23422.navixy.com/pro/applications/locator',
  REMITA_PUBLIC_KEY:
    'QzAwMDA0MDU1NDR8ODQzOTA2NzY4OHwxZTE4MTg2M2ViNWY3ZTA0MzNkZWM0MDViNmUzOTY5ZDYwMjM3ZGI1MzM2MWY3OTE4NjI3YWZlYjgyY2QzMjNiNmQwZGFjY2UxZDBiYjA1OWIzNjU0YzI4ZGE3NzMyNDI4OTk0NDY0ZGRhNzUzNzU5OWFiMjg1Zjg5NzRlNTIwNQ==',
  REMITA_GEN_RRR_URL:
    'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api',
  REMITA_MERCHANT_ID: '8439067688',
  REMITA_API_KEY: '5HCCY3MQ',
  REMITA_PENALTY_SERVICETYPE_ID: '9055697131',
  REMITA_FORWARDING_SERVICETYPE_ID: '9055616392',
  REMITA_CLEARING_SERVICETYPE_ID: '9055599678',
  REMITA_SUBSCRIPTION_SERVICETYPE_ID: '9055457831',
  GOOGLE_ANALYTICS: 'G-4PBDHS1XR0',
  TOKEN_EXPIRY_TIME: 5, // in minutes
  APP_INACTIVITY_TIME: 10, // in minutes
};

let config;
// console.log(process);

switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'staging':
    config = stage;
    break;
  case 'production':
    config = prod;
    break;
  default:
    config = dev;
    break;
}
const configObject = {
  ...config,
};
export default configObject;
