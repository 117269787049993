import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty, capitalize } from 'lodash';
import { showModal } from '../../redux/error/ErrorAction';
import { POST, GET } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import AppLoader from '../loaders/AppLoader';
import {
  accessGeneralOrCargoType,
  getDisputeStatusClassname,
  getQueryParamValue,
} from '../../helpers/utils';
import { DATE_FORMAT, TIME_FORMAT } from '../../helpers/constants';
import { LeftArrowSvg } from '..//UI/CommanUI';
import Pagination from './Pagination';
import { usePagination } from '../../helpers/customHooks';

const DisputesList = (props) => {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.enumData);
  const user = useSelector((state) => state.user.user);
  const operatorId = props.match?.params?.operatorId || '';

  // console.log(user);
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const [disputesList, setDisputesList] = useState([]);
  const [operatorDetails, setOperatorDetails] = useState({});

  useEffect(() => {
    getDisputesList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  const getDisputesList = (page, perPage) => {
    // console.log('type', type);
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    if (user.type === 'clearing_agent' || user.type === 'forwarding_agent') {
      payload.status = 4;
    }
    // for supervisor display of operator's pending disputes
    if (user.type === 'work_supervisor' && operatorId) {
      payload.operaotor_uuid = operatorId;
      payload.status = 1;
    }

    POST(`/jobs/getAllDisputes`, payload)
      .then(({ data }) => {
        // console.log('data', data);
        setTotalPages(data.totalRecords / perPage);
        setDisputesList(data.dispute);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  // fetch operator details
  const getOperatorDetails = (id) => {
    dispatch(showLoading());
    GET(`/users/getDetails/${id}`)
      .then(({ data }) => {
        // console.log('data', data.user);
        setOperatorDetails(data.user);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    if (user.type === 'work_supervisor' && operatorId) getOperatorDetails(operatorId);
  }, [operatorId]);

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  const handleViewDisputeDetails = (dispute) => {
    let route = '';

    switch (user.type) {
      case 'managenmnt_operator':
        route = 'operator';
        break;
      case 'legal_lawer':
        route = 'lawyer';
        break;
      case 'clearing_agent':
        route = 'clearing-agent';
        break;
      case 'forwarding_agent':
        route = 'forwarding-agent';
        break;
      case 'work_supervisor':
        route = `supervisor/operator/${operatorId}`;
        break;
      default:
        route = 'client';
        break;
    }

    props.history.push(`/${route}/dispute/details?id=${dispute.uuid}`);
  };

  let pageHeading = '';
  if (user.type === 'application_client' || user.type === 'application_corporate_client') {
    pageHeading = 'My Claims';
  } else if (user.type === 'work_supervisor') {
    pageHeading = (
      <>
        <span onClick={props.history.goBack} style={{ cursor: 'pointer', float: 'left' }}>
          <LeftArrowSvg />
        </span>
        Operator Pending Disputes
      </>
    );
  } else {
    pageHeading = 'My Disputes';
  }

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>{pageHeading}</h2>
            {user.type === 'work_supervisor' && (
              <h5 className="operator-heading">
                {operatorDetails?.first_name} {operatorDetails?.last_name} -{' '}
                {operatorDetails?.user_id}
              </h5>
            )}
            <div className="tab-pane active" id="resp-tab7">
              <div className="table-responsive clearingtable">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th>Job ID</th>
                      <th>Job Category</th>
                      <th>Job Type</th>
                      <th>{user.type === 'managenmnt_operator' ? 'Date Raised' : 'Status'}</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {disputesList.length > 0 &&
                      disputesList
                        .sort((a, b) => {
                          if (a.job.job_id < b.job.job_id) return -1;
                          if (a.job.job_id > b.job.job_id) return 1;
                          return 0;
                        })
                        .map((x, i) => (
                          <tr key={i}>
                            <td>
                              {x.job.job_id}-{x.job.is_forwarding_only ? 'T' : 'C'}
                            </td>
                            <td>
                              <h4>
                                {capitalize(serviceData.job.category[x.job.category]) || 'N/A'}
                              </h4>
                              <br />
                              <p>
                                {x.job.type && capitalize(serviceData.job.vehicle_type[x.job.type])}
                              </p>
                              <br />
                              <p>
                                {x.job.type_of_cargo &&
                                  accessGeneralOrCargoType(x.job, serviceData)}
                              </p>
                            </td>
                            <td>
                              <div>
                                <p
                                  className={`${
                                    x.job.is_forwarding_only
                                      ? 'alert  alert-info'
                                      : 'alert  alert-success'
                                  }`}
                                >
                                  {x.job.is_forwarding_only ? 'Transport' : 'Clearing'}
                                </p>
                              </div>
                            </td>
                            <td>
                              {user.type === 'managenmnt_operator' ? (
                                <>
                                  <div>{moment(x.created_at).format(DATE_FORMAT)}</div>
                                  <div>{moment(x.created_at).format(TIME_FORMAT)}</div>
                                </>
                              ) : (
                                <p className={getDisputeStatusClassname(x?.status)}>
                                  {x?.status && serviceData.dispute_status[x.status]}
                                </p>
                              )}
                            </td>
                            <td>
                              <div>
                                <a onClick={() => handleViewDisputeDetails(x)} className="review">
                                  View Details
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                {disputesList.length <= 0 && <h3>No Jobs Found!</h3>}
              </div>
              {disputesList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisputesList;
