import React from 'react';
import _, { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, Input, Label } from 'reactstrap';
import { Formik, getIn, FieldArray } from 'formik';
import NumberFormat from 'react-number-format';
import { object, string, array } from 'yup';
import TextField from '../../components/UI/TextField';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import { mergeContainersToJobs } from '../../helpers/utils';
import ErrorMessage from '../../components/global/ErrorMessage';

const JobUnbundleModal = ({ isOpen, toggle, jobDetails, onUnbundle }) => {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.enumData);

  const unbundleInitialVals = {
    packages: [],
    mergedJobs: [],
    shippingLine: '',
  };

  if (jobDetails?.containers?.length > 0) {
    // for vehicle type jobs
    unbundleInitialVals.packages = jobDetails.containers.map((c) => ({
      id: c.uuid,
      size: '',
      weight: '',
      no_of_hooks: '',
      mergejob: false,
    }));
  } else if (jobDetails?.category == 2 && jobDetails?.no_of_containers > 0) {
    // for general cargo type jobs
    const containers = [];
    for (let i = 0; i < jobDetails.no_of_containers; i++) {
      containers.push({
        size: '',
        weight: '',
        no_of_hooks: '',
        mergejob: false,
      });
    }
    unbundleInitialVals.packages = containers;
  }

  const handleSubmit = (values) => {
    // console.log('>> values', values);
    let newPackages = [];
    if (jobDetails?.category == 2) {
      newPackages = values.mergedJobs.map((np) => {
        return {
          ...np,
          containers: null,
        };
      });
    } else {
      newPackages = values.mergedJobs;
    }
    const payload = {
      job_uuid: jobDetails.uuid,
      packages: newPackages,
      shipping_line: values.shippingLine,
    };

    // console.log('>> payload', payload);
    dispatch(showLoading());
    POST(`/jobs/unbndleJob`, payload)
      .then(({ data }) => {
        // console.log('data', data);
        dispatch(showModal('Job unbundle successfully!', true));
        dispatch(hideLoading());
        onUnbundle();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} container="client-page" className="modal-xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5>Un-Bundle Jobs</h5>

          <Formik
            initialValues={unbundleInitialVals}
            onSubmit={handleSubmit}
            validationSchema={object().shape({
              // noofjob: number().required('Please Enter No of Jobs'),
              packages: array().of(
                object().shape({
                  size: string().required('Size is required'),
                  weight: string().required('Weight is required'),
                  // quantity: string().required('Weight is required'),
                }),
              ),
              mergedJobs: array().of(
                object().shape({
                  goods_value: string().required('Value of Goods is required'),
                  goods_nature: string().required('Nature of Goods is required'),
                }),
              ),
              shippingLine: string().required('Shipping Line is required'),
            })}
            enableReinitialize
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                validateForm,
                setTouched,
              } = renderProps;

              // console.log('>> values , touched,errors', { values, touched, errors });

              return (
                <>
                  <form className={'row'} onSubmit={handleSubmit}>
                    <div className="col-lg-12 col-sm-12 text-left">
                      <label>Shipping Line</label>
                      <TextField
                        type="text"
                        className="form-control"
                        placeholder="Shipping Line"
                        value={values.shippingLine}
                        name="shippingLine"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'weight') && getIn(errors, 'weight')}
                      />
                      <p className="text-danger">
                        <ErrorMessage name="shippingLine" />
                      </p>
                      <hr />
                    </div>
                    {values?.mergedJobs?.length === 0 && (
                      <FieldArray
                        name="packages"
                        render={(arrayHelpers) => (
                          <>
                            {/* <div className="col-sm-12 text-left">
                                <label>No of Jobs:</label>

                                <TextField
                                  type="number"
                                  className="form-control"
                                  placeholder="No of Jobs"
                                  value={values.noofjob}
                                  name="noofjob"
                                  min="1"
                                  max={jobDetails.no_of_containers}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value >= 1) {
                                      setFieldValue('noofjob', value);
                                      let tempContainer = [];
                                      for (let i = 0; i < value; i++) {
                                        if (jobDetails.category != 1) {
                                          tempContainer.push({
                                            size: '',
                                            weight: '',
                                            quantity: '',
                                          });
                                        } else {
                                          tempContainer.push({
                                            size: '',
                                            weight: '',
                                          });
                                        }
                                      }
                                      setFieldValue('packages', tempContainer);
                                    } else {
                                      setFieldValue('noofjob', value);
                                      setFieldValue('packages', []);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  error={getIn(touched, 'noofjob') && getIn(errors, 'noofjob')}
                                />
                              </div>
                            */}
                            {values.packages.map((containerData, index) => {
                              const colClassName =
                                jobDetails.category == 3
                                  ? 'col-lg-3 col-sm-6'
                                  : 'col-lg-4 col-sm-6';
                              const showMergeJobField =
                                containerData.size == 1 && jobDetails?.category == 1;

                              return (
                                <div
                                  key={index}
                                  className={
                                    jobDetails?.category == 1 ? 'row' : 'justify-content-center row'
                                  }
                                >
                                  <div className={`${colClassName} text-left`}>
                                    <label>Size (ft)</label>

                                    <select
                                      className={`form-control`}
                                      value={containerData.size}
                                      name={`packages.${index}.size`}
                                      onChange={(e) => {
                                        if (e.target.value === '2' && containerData.mergejob) {
                                          setFieldValue(`packages.${index}.mergejob`, false);
                                          setFieldValue(`packages.${index}.size`, e.target.value);
                                        } else {
                                          setFieldValue(`packages.${index}.size`, e.target.value);
                                        }
                                      }}
                                      onBlur={handleBlur}
                                    >
                                      <option value="">Size</option>
                                      {Object.keys(serviceData.truck.size).map((key) => (
                                        <option value={key} key={key}>
                                          {capitalize(serviceData.truck.size[key])}
                                        </option>
                                      ))}
                                    </select>
                                    <p className="text-danger">
                                      <ErrorMessage name={`packages[${index}].size`} />
                                    </p>
                                  </div>

                                  {jobDetails.category == 3 && (
                                    <div className={`${colClassName} text-left`}>
                                      <label>Quantity</label>

                                      <TextField
                                        type="text"
                                        className="form-control"
                                        placeholder="quantity"
                                        value={containerData.quantity}
                                        name={`packages.${index}.quantity`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          getIn(touched, 'quantity') && getIn(errors, 'quantity')
                                        }
                                      />

                                      <p className="text-danger">
                                        <ErrorMessage name={`packages[${index}].quantity`} />
                                      </p>
                                    </div>
                                  )}

                                  <div className={`${colClassName} text-left`}>
                                    <label>Weight (Tons)</label>
                                    <TextField
                                      type="number"
                                      min={1}
                                      className="form-control"
                                      placeholder="Weight(Tons)"
                                      value={containerData.weight}
                                      name={`packages.${index}.weight`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={getIn(touched, 'weight') && getIn(errors, 'weight')}
                                      onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
                                    />
                                    <p className="text-danger">
                                      <ErrorMessage name={`packages[${index}].weight`} />
                                    </p>
                                  </div>
                                  {showMergeJobField && (
                                    <div className={`${colClassName} text-left`}>
                                      <Label check htmlFor={`packages.${index}.mergejob`}>
                                        Merge this Job
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        id={`packages.${index}.mergejob`}
                                        className="fs-1"
                                        name={`packages.${index}.mergejob`}
                                        checked={containerData.mergejob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  )}
                                  <hr />
                                </div>
                              );
                            })}
                          </>
                        )}
                      />
                    )}
                    {!values?.mergedJobs?.length > 0 && (
                      <div className="col-12">
                        {jobDetails?.category == 1 && (
                          <span>
                            Note: Merging is done for every 2 - 20ft containers when "Merge this
                            job" option is checked.
                          </span>
                        )}
                        <div className="buttonsec1" style={{ textAlign: 'center' }}>
                          <button
                            type="button"
                            className="submitbut"
                            onClick={() => {
                              validateForm()
                                .then((result) => {
                                  // console.log('>> validateForm result', result);
                                  if (result?.packages?.length > 0) {
                                    setTouched(result, true);
                                  } else {
                                    const mergedJobs = mergeContainersToJobs(values.packages);
                                    setFieldValue('mergedJobs', mergedJobs);
                                  }
                                })
                                .catch((err) => {
                                  console.log('>> validateForm err', err);
                                });
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                    {values?.mergedJobs?.length > 0 && (
                      <FieldArray
                        name="mergedJobs"
                        render={(arrayHelpers) => (
                          <div>
                            <h4>Job Details</h4>
                            <p>Number of jobs: {values?.mergedJobs?.length}</p>
                            <div className="table-responsive clearingtable">
                              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                <thead>
                                  <tr>
                                    <th className="text-center">Job</th>
                                    <th className="text-center">Size (ft)</th>
                                    <th className="text-center">Weight (tons)</th>
                                    <th className="text-center">No of Containers</th>
                                    <th className="text-center">Nature of Goods</th>
                                    <th className="text-center">Value of Goods</th>
                                    <th className="text-center">Select Hooks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {values.mergedJobs.map((jobData, jobIndex) => {
                                    return (
                                      <tr key={jobIndex}>
                                        <td>#{jobIndex + 1}</td>
                                        <td>{serviceData.truck.size[jobData.size]}</td>
                                        <td>{jobData.weight}</td>
                                        <td>{jobData.containers.length}</td>
                                        <td>
                                          <div className="mt-3">
                                            <select
                                              className="form-control"
                                              value={jobData.goods_nature}
                                              onChange={handleChange}
                                              id={`mergedJobs.${jobIndex}.goods_nature`}
                                              name={`mergedJobs.${jobIndex}.goods_nature`}
                                              onBlur={handleBlur}
                                              style={{
                                                width: 150,
                                              }}
                                            >
                                              <option value="">Select</option>
                                              {Object.keys(serviceData.job.nature_of_goods).map(
                                                (key) => (
                                                  <option value={key} key={key}>
                                                    {`${serviceData.job.nature_of_goods[key]} Risk`}
                                                  </option>
                                                ),
                                              )}
                                            </select>
                                            <p className="text-danger">
                                              {_.get(
                                                errors,
                                                `mergedJobs[${jobIndex}].goods_nature`,
                                              )}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="mt-3">
                                            <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              decimalSeparator="."
                                              value={jobData.goods_value}
                                              className="form-control"
                                              displayType="input"
                                              type="text"
                                              thousandSeparator={true}
                                              prefix=""
                                              id={`mergedJobs.${jobIndex}.goods_value`}
                                              name={`mergedJobs.${jobIndex}.goods_value`}
                                              onValueChange={(e) =>
                                                setFieldValue(
                                                  `mergedJobs[${jobIndex}].goods_value`,
                                                  e.value,
                                                )
                                              }
                                              placeholder="0000"
                                            />
                                            <p className="text-danger">
                                              {_.get(errors, `mergedJobs[${jobIndex}].goods_value`)}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="mt-3">
                                            <select
                                              className="form-control"
                                              value={jobData.no_of_hooks}
                                              onChange={handleChange}
                                              id={`mergedJobs.${jobIndex}.no_of_hooks`}
                                              name={`mergedJobs.${jobIndex}.no_of_hooks`}
                                              onBlur={handleBlur}
                                              style={{
                                                width: 150,
                                              }}
                                            >
                                              <option value="">Select</option>
                                              <option value="4">4</option>
                                              <option value="6">6</option>
                                              <option value="8">8</option>
                                            </select>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                              <div className="buttonsec1">
                                <button
                                  type="button"
                                  className="submitbut color-secondary float-start"
                                  onClick={() => setFieldValue('mergedJobs', [])}
                                >
                                  Edit
                                </button>
                                <button type="submit" className="submitbut float-end">
                                  Proceed to Unbundle
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    )}
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default JobUnbundleModal;
